import './src/components/layout/reset.scss'
import './src/components/layout/global.scss'

// Shortcut to global styles
const style = document.documentElement.style

// Keep track of various heights
const sizeListener = () => style.setProperty('--window-height', window.innerHeight + 'px')
sizeListener()
window.addEventListener('resize', sizeListener)
